/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { locationColumns } from "./Data/LocationListData";
import {
  getAllLocationsV3,
  getLocationsV3,
} from "../../Redux/APIs/locationsAPI";
import LocationsModuleIcon from "../../Common/Svgs/LocationsModuleIcon";
import LocationListHeader from "./phase3/LocationListHeader";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

function LocationList() {
  const dispatch = useDispatch();

  const { locationsList } = useSelector((state) => state.locations);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);

  const LOCAL_DEFAULT_LIMIT = 10;

  // check if three dots permission exist (cursor pointer scenario)
  const hasThreeDotsPermission = usePermission([
    PERMISSIONS.LOCATION_EDIT,
    PERMISSIONS.LOCATION_DELETE,
  ]);

  // fetch all locations and pagination based location
  useEffect(() => {
    dispatch(getAllLocationsV3());
    dispatch(
      getLocationsV3({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );
  }, [dispatch, currentPage]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Locations">
      <div>
        <LocationListHeader currentPage={currentPage} />

        <CustomTable
          scrollX={0}
          columns={locationColumns}
          data={locationsList.result}
          emptyState={
            <LocationsModuleIcon size={16} className="text-dark-grey-text" />
          }
          rowClassName={
            hasThreeDotsPermission ? "group cursor-pointer" : "group"
          }
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            total: locationsList.totalRecords,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            itemRender: (current, type, originalElement) => {
              const lastPage = locationsList.totalRecords / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>
    </CustomLayout>
  );
}
export default LocationList;
