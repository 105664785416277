import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomAvatar from "../Components/CustomAvatar";
import SideBarItem from "./SideBarItem";
import CustomDropdown from "../Components/CustomDropdown";
import LogoutCTA from "../../Modules/Home/LogoutCTA";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";
import SettingsIcon, {
  ActiveSettingsIcon,
} from "../../Common/Svgs/SettingsIcon";
import getThemeColor from "../../Utils/getThemeColor";
import useSideBarData from "./data/useSideBarData";

const SettingsIconForSidebar = ({ pageName, theme }) => {
  const navigate = useNavigate();
  const centerColor = getThemeColor(theme);

  return (
    <button
      type="button"
      className="relative flex flex-col items-center cursor-pointer group"
      onClick={() => navigate(`/settings`)}
    >
      {pageName === "Settings" ? (
        <ActiveSettingsIcon
          className="text-sidebar-active"
          centerColor={centerColor}
        />
      ) : (
        <SettingsIcon className="w-6 h-6 stroke-primary-50 group-hover:stroke-sidebar-active" />
      )}
      <p
        className={`font-aileron font-light text-xs leading-[14px] pt-[3px] group-hover:visible text-sidebar-active ${
          pageName === "Settings"
            ? "visible group-hover:text-sidebar-active"
            : "invisible group-hover:text-sidebar-active"
        }
        `}
      >
        Settings
      </p>
    </button>
  );
};

const Sidebar = ({ pageName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails, theme, companyLogo } = useSelector(
    (state) => state.loggedInUser,
  );

  const dataForLogout = [
    {
      key: 1,
      label: <LogoutCTA />,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(updateLoggedInUser({ key: "userDetails", value: {} }));
    navigate("/");
  };

  const sideBarData = useSideBarData(theme, navigate).filter(
    (item) => item.hasPermission,
  );

  return (
    <aside className="h-screen bg-primary border-r border-light-grey w-18 min-w-[72px]">
      <div className="flex flex-col items-center justify-between h-full py-6">
        <div className="flex flex-col items-center">
          <img
            src={companyLogo}
            className="w-8 h-8 mx-auto mb-4"
            alt="Songa Mobility"
          />

          {sideBarData.map(
            ({ id, icon, selectedIcon, title, customNavigate }) => (
              <SideBarItem
                key={id}
                pageName={pageName}
                icon={icon}
                selectedIcon={selectedIcon}
                title={title}
                customNavigate={customNavigate}
              />
            ),
          )}
        </div>

        <div>
          <SettingsIconForSidebar pageName={pageName} theme={theme} />
          <CustomDropdown
            items={dataForLogout}
            placement="topRight"
            handleClick={handleLogout}
          >
            <div className="mt-3 cursor-pointer">
              <CustomAvatar title={userDetails.firstName} />
            </div>
          </CustomDropdown>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
