import React from 'react';
import getActivityTag from './getActivityTag';
import { LOCATION_TYPE } from '../../Location/Data';

// const locationStops = (geoLocations) => {
//   const locationStopsForDropdown = geoLocations
//     .filter((loc) => !loc.is_origin)
//     .map((geolocation) => ({
//       key: geolocation.id,
//       value: geolocation.name,
//       label: (
//         <div className="flex items-center px-2 py-2 gap-x-3">
//           <p className="text-xs font-semibold text-black capitalize font-aileron">
//             {geolocation.name}
//           </p>
//         </div>
//       ),
//     }));

//   return locationStopsForDropdown;
// };

// phase 3

const locationStops = (locations) => {
  const locationStopsForDropdown = locations
    .filter(
      (loc) =>
        loc.type === LOCATION_TYPE.COLLECTION ||
        loc.type === LOCATION_TYPE.DELIVERY ||
        loc.type === LOCATION_TYPE.AGGREGATION,
    )
    .map((item) => ({
      key: item.id,
      value: item.name,
      label: (
        <div className="flex items-center px-2 py-2 gap-x-3">
          <p className="text-xs font-semibold text-black capitalize font-aileron">
            {item.name}
          </p>
        </div>
      ),
    }));

  return locationStopsForDropdown;
};

export const originStops = (locations) => {
  const originStopsForDropdown = locations
    .filter((loc) => loc.type === LOCATION_TYPE.PARKING_LOT)
    .map((item) => ({
      key: item.id,
      value: item.name,
      label: (
        <div className="flex items-center px-2 py-2 gap-x-3">
          <p className="text-xs font-semibold text-black capitalize font-aileron">
            {item.name}
          </p>
        </div>
      ),
    }));

  return originStopsForDropdown;
};

export const activities = [
  {
    key: 1,
    value: 'collection',
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        {getActivityTag('1')}
      </div>
    ),
  },
  {
    key: 2,
    value: 'delivery',
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        {getActivityTag('2')}
      </div>
    ),
  },
  {
    key: 3,
    value: 'aggregation',
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        {getActivityTag('3')}
      </div>
    ),
  },
];

export const cargoItems = [
  {
    key: 1,
    value: 'Milk',
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        <p className="text-xs font-semibold text-black capitalize font-aileron">
          Milk
        </p>
      </div>
    ),
  },
  {
    key: 2,
    value: 'Banana',
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        <p className="text-xs font-semibold text-black capitalize font-aileron">
          Banana
        </p>
      </div>
    ),
  },
  {
    key: 3,
    value: 'Beans',
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        <p className="text-xs font-semibold text-black capitalize font-aileron">
          Beans
        </p>
      </div>
    ),
  },
];

export default locationStops;
