/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import CustomModal from './CustomModal';
import { QR_CODE_TYPE } from '../../../Modules/QRCode/data';
import TrikeIcon from '../../../Common/Svgs/TrikeIcon';
import BatteryIcon from '../../../Common/Svgs/BatteryIcon';
import UserIcon from '../../../Common/Svgs/UserIcon';
import CloseIcon from '../../../Common/Svgs/CloseIcon';
import Button from '../CustomButton';

const ASSET = ['TRIKE', 'BATTERY', 'FARMER'];

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { viewModal } = useSelector((state) => state.qrCode);

  return (
    <div className="h-[100vh] flex items-center justify-center" ref={ref}>
      <div>
        <div className="flex items-center justify-center gap-x-1">
          <p className="font-poppins text-base font-semibold text-center">
            {ASSET[viewModal?.data?.assetType - 1]} :
          </p>
          <p className="font-poppins text-base font-semibold text-center">
            {viewModal?.data?.assetId || '--'}
          </p>
        </div>
        <QRCode
          className="mt-5"
          value={JSON.stringify(viewModal.data)}
          size={270}
          bgColor="#fff"
          title={viewModal?.data?.assetId}
        />
      </div>
    </div>
  );
});

const QRCodeModal = ({ visible = false, onCancel, type }) => {
  const { viewModal } = useSelector((state) => state.qrCode);

  const contentToPrintRef = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: 'Print This Document',
    // onBeforePrint: () => console.log('before printing...'),
    // onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true,
  });

  const renderIcon = () => {
    switch (type) {
      case QR_CODE_TYPE.TRIKE:
        return (
          <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center">
            <TrikeIcon size={24} className="text-white" />
          </div>
        );
      case QR_CODE_TYPE.BATTERY:
        return (
          <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center">
            <BatteryIcon size={24} className="stroke-white fill-none" />
          </div>
        );
      case QR_CODE_TYPE.FARMER:
        return (
          <div className="w-12 h-12 bg-primary rounded-full flex items-center justify-center">
            <UserIcon size={24} className="stroke-white fill-none" />
          </div>
        );
      default:
        return null;
    }
  };

  const onConfirm = () => {
    handlePrint(null, () => contentToPrintRef.current);
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        className=""
        style={{
          boxShadow:
            '12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)',
        }}
      >
        <div className="flex items-center justify-between pl-4 pt-4 pb-6 pr-6">
          <div className="flex items-center gap-x-3">
            {renderIcon()}
            <p className="font-poppins text-base font-medium">
              {viewModal?.data?.assetId || '--'}
            </p>
          </div>
          <button type="button" className="mr-2" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="bg-light-grey h-[334px] flex items-center justify-center">
          <div style={{ display: 'none' }}>
            <ComponentToPrint ref={contentToPrintRef} />
          </div>
          <div className="">
            <QRCode
              className=""
              value={JSON.stringify(viewModal.data)}
              size={270}
              bgColor="#DFDFDF"
              title={viewModal?.data?.assetId}
            />
          </div>
        </div>

        <div className="p-6 flex items-center gap-x-3 justify-end">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Close
          </Button>

          <Button onClick={onConfirm}>Print Code</Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default QRCodeModal;
