/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import TrikePermissions from "./TrikePermissions";
import { PERMISSIONS } from "../../Login/constants";
import BatteryPermissions from "./BatteryPermissions";
import { client } from "../../../Utils/axiosClient";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import TripPermissions from "./TripPermissions";
import UserPermissions from "./UserPermissions";
import LocationPermissions from "./LocationPermissions";
import InventoryPermissions from "./InventoryPermissions";
import ServicePermissions from "./ServicePermissions";
import SettingsPermissions from "./SettingsPermissions";
import {
  getSystemRoles,
  getUserByEmail,
} from "../../../Redux/APIs/systemUsersAPI";

const CreateRole = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetails, currentRole, duplicateRole } = useSelector(
    (state) => state.loggedInUser,
  );

  const { control, formState, handleSubmit, setError } = useForm({
    defaultValues: {
      name: id && !duplicateRole ? currentRole?.name : "",
    },
  });

  const [permissions, setPermissions] = useState([
    PERMISSIONS.TRIKE_VIEW,
    PERMISSIONS.BATTERY_VIEW,
    PERMISSIONS.TRIP_VIEW,
    PERMISSIONS.DRIVER_VIEW,
    PERMISSIONS.FARMER_VIEW,
    PERMISSIONS.LOCATIONS_VIEW,
    PERMISSIONS.INVENTORY_VIEW,
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SETTINGS_GENERAL_VIEW,
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
  ]);

  const [focus, setFocus] = useState(false);

  const onCancel = () => {
    navigate(-1);
  };

  const onCreate = async (e) => {
    const payload = {
      roleName: e.name.toLowerCase(),
      permissions,
      createdById: userDetails.userId,
    };

    try {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: true,
        }),
      );

      if (id) {
        payload.roleId = parseInt(id, 10);

        await client.post(
          `${process.env.REACT_APP_API_URL}/auth/update-system-role`,
          payload,
        );

        if (userDetails.roleId == id) {
          await dispatch(getUserByEmail({ email: userDetails.email }));
        }

        dispatch(
          updateLoggedInUser({
            key: "toastMessageModal",
            value: {
              visible: true,
              title: "1 Role Edited",
              description: `${payload.roleName} role was edited successfully.`,
            },
          }),
        );
      } else {
        await client.post(
          `${process.env.REACT_APP_API_URL}/auth/create-system-role`,
          payload,
        );

        dispatch(
          updateLoggedInUser({
            key: "toastMessageModal",
            value: {
              visible: true,
              title: "1 Role Created",
              description: `${payload.roleName} role was created successfully.`,
            },
          }),
        );
      }

      await dispatch(getSystemRoles());

      navigate(-1);
    } catch (error) {
      console.log(error);

      setError(error.response.data.type, {
        type: "server",
        message: error.response.data.message,
      });
    } finally {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );

      dispatch(
        updateLoggedInUser({
          key: "duplicateRole",
          value: false,
        }),
      );
    }
  };

  useEffect(() => {
    if (id || duplicateRole) {
      setPermissions([...currentRole.permissions]);
    }

    return () => {
      dispatch(
        updateLoggedInUser({
          key: "duplicateRole",
          value: false,
        }),
      );
    };
  }, [id, duplicateRole, currentRole, dispatch]);

  return (
    <CustomLayout pageName="Create Role">
      <div className="w-full bg-background-white">
        <div className="px-6">
          <div className="flex items-center justify-between py-6">
            <p className="font-poppins text-base font-medium capitalize">
              Create a new user role
            </p>

            <div className="flex items-center gap-x-3">
              <Button
                size="filter"
                width="generateUser"
                colors="grey"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                size="filter"
                width="generateUser"
                type="submit"
                onClick={handleSubmit(onCreate)}
              >
                {id ? "Save" : "Create"}
              </Button>
            </div>
          </div>

          <div className="w-full bg-light-grey h-[1px]" />
        </div>
      </div>

      {/* TODO - discuss about the hide-scrollbar */}
      <div className="bg-background-white w-full h-[calc(100vh-88px-72px)] overflow-y-scroll hide-scrollbar pb-8">
        <div className="px-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mt-6 mb-1.5">
            Name your role
          </p>

          <CustomInputWithController
            intent="createUser"
            control={control}
            formState={formState}
            focus={focus}
            setFocus={(e) => setFocus(e)}
            name="name"
            placeholder="Enter Name"
            rules={{
              required: "Required.",
            }}
          />

          <p className="font-poppins text-xs font-medium capitalize my-6">
            Modules and permissions
          </p>
        </div>

        <TrikePermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <BatteryPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <TripPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <UserPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <LocationPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <InventoryPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <ServicePermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />

        <SettingsPermissions
          permissions={permissions}
          setPermissions={setPermissions}
        />
      </div>
    </CustomLayout>
  );
};

export default CreateRole;
