/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { BatteryKPIData, batteriesColumns } from "./Data/BatteryListData";
import CustomKPI from "../../Core/Components/CustomKpi";
import { getBatteryV3, getKPI } from "../../Redux/APIs/batteriesAPI";
import { updateBatteries } from "../../Redux/Slices/batteries.slice";
import BatteryIcon from "../../Common/Svgs/BatteryIcon";
import BatteryListHeader from "./phase3/BatteryListHeader";
import ToastMessageModal from "./phase3/ToastMessageModal";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { DEFAULT_LIMIT } from "../../Utils/constants";
import DeleteBatteryModal from "./phase3/DeleteBatteryModal";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import QRCodeModal from "../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

function BatteriesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { batteryListV3, kpiData, showToastMessage, deleteBatteryModal } =
    useSelector((state) => state.batteries);

  const { viewModal } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [showKpi, setShowKpi] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // check if the user has permission to acccess the module
  const hasPermission = usePermission([
    PERMISSIONS.BATTERY_VIEW,
    PERMISSIONS.BATTERY_VIEW_AND_EDIT,
  ]);

  // check if the user has permission to acccess the service module
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  const handleRowClick = (record) => {
    if (hasPermission) {
      dispatch(
        updateBatteries({
          key: "currentBattery",
          value: batteryListV3.result.find((item) => item.id === record.id),
        }),
      );

      navigate(`/batteries/${record.registrationNo}`);
    }
  };

  useEffect(() => {
    dispatch(
      getBatteryV3({ offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT }),
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateBatteries({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  useEffect(() => {
    dispatch(getKPI());
  }, [dispatch]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Batteries">
      <div className="bg-background">
        <div className="flex items-center justify-between w-full h-40 p-6 gap-x-6">
          {BatteryKPIData(kpiData, navigate)?.map((i) => (
            <CustomKPI
              key={i.title}
              image={i?.image}
              title={i?.title}
              count={i?.count}
              total={i?.total}
              icon={hasServicePermission && i?.icon}
              handleOnClick={i.onClick}
              redirect={hasServicePermission && i?.id === 3}
            />
          ))}
        </div>
        <div
          className={`absolute w-[calc(100vw-72px)] ${
            showKpi ? "-translate-y-[160px]" : ""
          }  transition-transform duration-500`}
        >
          <BatteryListHeader
            showKpi={showKpi}
            setShowKpi={setShowKpi}
            currentPage={currentPage}
          />
          <CustomTable
            scrollX={0}
            columns={batteriesColumns}
            data={batteryListV3.result}
            onRowClick={handleRowClick}
            emptyState={
              <BatteryIcon className="w-6 h-6 fill-none stroke-dark-grey-text" />
            }
            rowClassName={(record) => {
              return hasPermission ? "group cursor-pointer" : "group";
            }}
            pagination={{
              pageSize: DEFAULT_LIMIT,
              total: batteryListV3.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              itemRender: (current, type, originalElement) => {
                const lastPage = batteryListV3.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <DeleteBatteryModal
        visible={deleteBatteryModal}
        onCancel={() =>
          dispatch(
            updateBatteries({
              key: "deleteBatteryModal",
              value: false,
            }),
          )
        }
        batteryCurrentPage={currentPage}
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateBatteries({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
}
export default BatteriesList;
