import React from "react";
import { useNavigate } from "react-router-dom";

const SideBarItem = ({
  pageName,
  icon,
  selectedIcon,
  title,
  customNavigate = null,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (customNavigate) {
      customNavigate();
    } else {
      navigate(`/${title.toLowerCase()}`);
    }
  };

  return (
    <button
      type="button"
      className="flex flex-col items-center mt-5 cursor-pointer group"
      onClick={onClick}
    >
      {pageName === title ? selectedIcon : icon}
      <p
        className={`font-aileron font-light text-xs leading-[14px] pt-[3px] group-hover:visible text-sidebar-active ${
          pageName === title
            ? "visible group-hover:text-sidebar-active"
            : "invisible group-hover:text-sidebar-active"
        }
        `}
      >
        {title}
      </p>
    </button>
  );
};

export default SideBarItem;
