/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import QRCodeListHeader from "./QRCodeListHeader";
import CustomTable from "../../Core/Components/CustomTable";
import { qrCodeColumns } from "./data";
import QRCodeIcon from "../../Common/Svgs/QRCodeIcon";
import QRCodeModal from "../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import { client } from "../../Utils/axiosClient";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import useDebounce from "../../Core/hooks/useDebounce";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const QRCodeList = () => {
  const dispatch = useDispatch();

  const { viewModal } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState([]);
  const [qrCodeList, setQrCodeList] = useState({
    result: [],
    totalRecords: 0,
  });

  const debounceSearch = useDebounce(search, 500);

  const getQRCodes = useCallback(async () => {
    try {
      dispatch(
        updateQrCode({
          key: "loading",
          value: true,
        }),
      );

      const filterObj = {
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      };

      if (debounceSearch) filterObj.searchQuery = debounceSearch;
      if (typeFilter.length) filterObj.type = typeFilter;

      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/qr-code/get-qr-code`,
        {
          params: filterObj,
        },
      );

      setQrCodeList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateQrCode({
          key: "loading",
          value: false,
        }),
      );
    }
  }, [dispatch, currentPage, debounceSearch, typeFilter]);

  useEffect(() => {
    getQRCodes();
  }, [getQRCodes]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="QR Codes">
      <QRCodeListHeader
        search={search}
        setSearch={setSearch}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
      />
      <CustomTable
        scrollX={0}
        columns={qrCodeColumns}
        data={qrCodeList.result}
        emptyState={<QRCodeIcon size={16} className="text-dark-grey-text" />}
        rowClassName="group cursor-pointer"
        pagination={{
          pageSize: LOCAL_DEFAULT_LIMIT,
          total: qrCodeList.totalRecords,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPage(page);
          },
          itemRender: (current, type, originalElement) => {
            const lastPage = qrCodeList.totalRecords / current;

            if (type === "prev" && current === 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "prev" && current > 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next" && lastPage <= 10) {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{ pointerEvents: "none" }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next") {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            return originalElement;
          },
        }}
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />
    </CustomLayout>
  );
};
export default QRCodeList;
