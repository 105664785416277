const getColorAndIcon = (type) => {
  const inputType = type.toString();
  switch (inputType) {
    case "1":
      return {
        colors: "assemblyStation",
        icon: "assembly-station.svg",
      };
    case "2":
      return {
        colors: "swapStation",
        icon: "swap-station.svg",
      };
    case "3":
      return {
        colors: "parkingLot",
        icon: "parking.svg",
      };
    case "4":
      return {
        colors: "farm",
        icon: "farm.svg",
      };
    case "5":
      return {
        icon: "trike.svg",
        topIcon: "trike-motion.svg",
      };
    default:
      return {
        icon: "trike.svg",
        topIcon: "trike-motion.svg",
      };
  }
};
export default getColorAndIcon;
